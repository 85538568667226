<template>
    <div style="height: 100%;position:relative;">
        <!--搜索区域-->
        <div class="header">
            <div style="color:#409EFF">
                项目名称：
                <el-input v-model="smartHomeName" size="medium" placeholder="请输入内容"></el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="getProjectList()">搜索</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addUserfun">新增</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="userData" style="width: 100%">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="smartHomeName" label="项目名称"></el-table-column>
                <el-table-column prop="smartHomeAddr" label="项目地址"></el-table-column>
                <el-table-column prop="deviceCount" label="设备数量" width="200"></el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="handleEdit(scope)" style="cursor : pointer;">编辑</span>
                            <span @click="handleDelete(scope)" style="cursor : pointer;">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>

        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="25%">
            <el-form :model="projectForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="项目名称" prop="smartHomeName">
                    <el-input type="text" v-model="projectForm.smartHomeName" autocomplete="off" v-bind:disabled="title=='编辑'"></el-input>
                </el-form-item>
                <el-form-item label="项目地址" prop="smartHomeAddr">
                    <el-input type="text" v-model="projectForm.smartHomeAddr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitUserMsg('ruleForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
    import Mypage from '../../../components/MyPagination.vue'
    import {hotelList,addProject,editProject,projectDetail,delProject} from './project'

    export default {
        components: {
            Mypage
        },
        name: "SmartHomeProject",
        data() {
            return {
                dialogVisible: false, //打开新增模态框
                title:'新增',
                hotelData:[], //酒店列表
                smartHomeName:'',
                total:10,
                limit: 10,
                page: 1,
                userData: [],
                //新增项目模态框数据
                projectForm: {
                    smartHomeName: '', //项目名称
                    smartHomeAddr: '', //项目地址
                },
                rules: {
                    smartHomeName: [
                        {required: true, message: "请输入项目名称",trigger: "blur",},
                    ],
                    smartHomeAddr: [
                        {required: false, message: "请输入项目地址", trigger: "blur"},
                    ],
                }
            }
        },
        methods: {
            //    查询项目列表
            getProjectList() {
                hotelList( this.smartHomeName, this.limit, this.page,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.userData=result["data"]["data"]["records"]
                        this.total=parseInt(result["data"]["data"]["total"])
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            addUserfun(){
                this.dialogVisible = true;
                this.title='新增'
                this.projectForm= {
                    smartHomeName: '', //用户名
                    smartHomeAddr: '', //密码
                }
            },
            //    编辑
            handleEdit(scope) {
                console.log('scope',scope.row)
                this.dialogVisible = true
                this.title='编辑'
                projectDetail(scope.row.smartHomeId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.projectForm=result["data"]["data"]
                    }
                })
                this.projectForm.smartHomeId=scope.row.smartHomeId
            },
            //    删除
            handleDelete(scope) {
                this.$confirm('确认删除该项目吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delProject(scope.row.smartHomeId,this.$cookies.get("access_token")).then((result)=>{
                        if (result["data"].code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getProjectList()
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 提交酒店模态框数据
            submitUserMsg(formName) {
                if(this.title=='新增'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            console.log('form',this.projectForm)
                            addProject(this.projectForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result.data.code==0){
                                    this.$message({
                                        message: '添加成功！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.getProjectList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                }

                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'success'
                                });
                                this.dialogVisible = false
                            })
                        }
                    })
                }else if(this.title=='编辑'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            editProject(this.projectForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result["data"].code==0){
                                    this.$message({
                                        message: '修改成功！',
                                        type: 'success'
                                    });

                                    this.getProjectList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                }
                                this.dialogVisible = false
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'error'
                                });
                                this.dialogVisible = false
                            })
                        }
                    })
                }

            },
            //    分页
            changePage(num){
                this.page=num;
                this.getProjectList()
            }


        },
        created() {  /*生命周期函数*/
            this.getProjectList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

</style>