import request from '../../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)

//根据查询条件获取项目列表
export function hotelList(smartHomeName,limit,page,token) {
    return request({
        url:"/hotel/smartHome/getSmartHomeList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            smartHomeName:smartHomeName,
            limit:limit,
            page:page,
        }
    })
}

//新增项目
export function addProject(data,token){
    return request({
        url:"/hotel/smartHome/addSmartHome",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//获取项目详情
export function projectDetail(smartHomeId,token){
    return request({
        url:"/hotel/smartHome/getSmartHomeDetails",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            smartHomeId:smartHomeId
        }
    })
}

//修改项目
export function editProject(data,token){
    return request({
        url:"/hotel/smartHome/updateSmartHome",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//删除项目
export function delProject(smartHomeId,token){
    return request({
        url:"/hotel/smartHome/deleteSmartHome",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            smartHomeId:smartHomeId
        }
    })
}